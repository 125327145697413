<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='订单号' prop='orderid' :label-width="formLabelWidth">
              <el-input placeholder='请输入订单号' clearable autocomplete="off" v-model='form.orderid' size="small"  />
            </el-form-item>
            <el-form-item label='用户id' prop='userid' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户id' clearable autocomplete="off" v-model='form.userid' size="small"  />
            </el-form-item>
            <el-form-item label='信用卡卡号' prop='cardnumber' :label-width="formLabelWidth">
              <el-input placeholder='请输入信用卡卡号' clearable autocomplete="off" v-model='form.cardnumber' size="small"  />
            </el-form-item>
            <el-form-item label='信用卡安全码' prop='cvv' :label-width="formLabelWidth">
              <el-input placeholder='请输入信用卡安全码' clearable autocomplete="off" v-model='form.cvv' size="small"  />
            </el-form-item>
            <el-form-item label='信用卡有效期(月份:MM)' prop='month' :label-width="formLabelWidth">
              <el-input placeholder='请输入信用卡有效期(月份:MM)' clearable autocomplete="off" v-model='form.month' size="small"  />
            </el-form-item>
            <el-form-item label='信用卡有效期(年份:YYYY)' prop='year' :label-width="formLabelWidth">
              <el-input placeholder='请输入信用卡有效期(年份:YYYY)' clearable autocomplete="off" v-model='form.year' size="small"  />
            </el-form-item>
            <el-form-item label='名字' prop='firstname' :label-width="formLabelWidth">
              <el-input placeholder='请输入名字' clearable autocomplete="off" v-model='form.firstname' size="small"  />
            </el-form-item>
            <el-form-item label='姓氏' prop='lastname' :label-width="formLabelWidth">
              <el-input placeholder='请输入姓氏' clearable autocomplete="off" v-model='form.lastname' size="small"  />
            </el-form-item>
            <el-form-item label='公司名称' prop='company' :label-width="formLabelWidth">
              <el-input placeholder='请输入公司名称' clearable autocomplete="off" v-model='form.company' size="small"  />
            </el-form-item>
            <el-form-item label='国家/地区' prop='country' :label-width="formLabelWidth">
              <el-input placeholder='请输入国家/地区' clearable autocomplete="off" v-model='form.country' size="small"  />
            </el-form-item>
            <el-form-item label='州' prop='state' :label-width="formLabelWidth">
              <el-input placeholder='请输入州' clearable autocomplete="off" v-model='form.state' size="small"  />
            </el-form-item>
            <el-form-item label='城市' prop='city' :label-width="formLabelWidth">
              <el-input placeholder='请输入城市' clearable autocomplete="off" v-model='form.city' size="small"  />
            </el-form-item>
            <el-form-item label='详细地址' prop='address' :label-width="formLabelWidth">
              <el-input placeholder='请输入详细地址' clearable autocomplete="off" v-model='form.address' size="small"  />
            </el-form-item>
            <el-form-item label='邮政编码' prop='postalcode' :label-width="formLabelWidth">
              <el-input placeholder='请输入邮政编码' clearable autocomplete="off" v-model='form.postalcode' size="small"  />
            </el-form-item>
            <el-form-item label='电话' prop='phone' :label-width="formLabelWidth">
              <el-input placeholder='请输入电话' clearable autocomplete="off" v-model='form.phone' size="small"  />
            </el-form-item>
            <el-form-item label='电子邮件' prop='email' :label-width="formLabelWidth">
              <el-input placeholder='请输入电子邮件' clearable autocomplete="off" v-model='form.email' size="small"  />
            </el-form-item>
            <el-form-item label='货币代码' prop='currency' :label-width="formLabelWidth">
              <el-input placeholder='请输入货币代码' clearable autocomplete="off" v-model='form.currency' size="small"  />
            </el-form-item>
            <el-form-item label='支付金额' prop='amount' :label-width="formLabelWidth">
              <el-input placeholder='请输入支付金额' clearable autocomplete="off" v-model='form.amount' size="small"  />
            </el-form-item>
            <el-form-item label='支付方式' prop='paytype' :label-width="formLabelWidth">
              <el-input placeholder='请输入支付方式' clearable autocomplete="off" v-model='form.paytype' size="small"  />
            </el-form-item>
            <el-form-item label='支付状态' prop='paystatus' :label-width="formLabelWidth">
              <el-input placeholder='请输入支付状态' clearable autocomplete="off" v-model='form.paystatus' size="small"  />
            </el-form-item>
            <el-form-item label='支付备注' prop='payremark' :label-width="formLabelWidth">
              <el-input placeholder='请输入支付备注' clearable autocomplete="off" v-model='form.payremark' size="small"  />
            </el-form-item>
            <el-form-item label='用户ip' prop='ip' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户ip' clearable autocomplete="off" v-model='form.ip' size="small"  />
            </el-form-item>
            <el-form-item label='赢支付交易id' prop='tradeid' :label-width="formLabelWidth">
              <el-input placeholder='请输入赢支付交易id' clearable autocomplete="off" v-model='form.tradeid' size="small"  />
            </el-form-item>
            <el-form-item label='赢支付请求id' prop='requestid' :label-width="formLabelWidth">
              <el-input placeholder='请输入赢支付请求id' clearable autocomplete="off" v-model='form.requestid' size="small"  />
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertPayorder,updatePayorder,deletePayorder,bacthDelPayorder } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'orderid',label: '订单号'},
          {prop: 'userid',label: '用户id'},
          {prop: 'cardnumber',label: '信用卡卡号'},
          {prop: 'cvv',label: '信用卡安全码'},
          {prop: 'month',label: '卡有效期(月份:MM)'},
          {prop: 'year',label: '卡有效期(年份:YYYY)'},
          {prop: 'firstname',label: '名字'},
          {prop: 'lastname',label: '姓氏'},
          {prop: 'company',label: '公司名称'},
          {prop: 'country',label: '国家/地区'},
          {prop: 'state',label: '州'},
          {prop: 'city',label: '城市'},
          {prop: 'address',label: '详细地址'},
          {prop: 'postalcode',label: '邮政编码'},
          {prop: 'phone',label: '电话'},
          {prop: 'email',label: '电子邮件'},
          {prop: 'currency',label: '货币代码'},
          {prop: 'amount',label: '支付金额'},
          {prop: 'paytype',label: '支付方式'},
          {prop: 'paystatus',label: '支付状态'},
          {prop: 'payremark',label: '支付备注'},
          {prop: 'ip',label: '用户ip'},
          {prop: 'tradeid',label: '赢支付交易id'},
          {prop: 'requestid',label: '赢支付请求id'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
            type: 'input',
            id: 'orderid',
            label: '订单号',
            el: {placeholder: '请输入订单号', clearable: true},
          },
          {
            type: 'input',
            id: 'userid',
            label: '用户id',
            el: {placeholder: '请输入用户id', clearable: true},
          },
          {
            type: 'input',
            id: 'cardnumber',
            label: '信用卡卡号',
            el: {placeholder: '请输入信用卡卡号', clearable: true},
          },
          {
            type: 'input',
            id: 'firstname',
            label: '名字',
            el: {placeholder: '请输入名字', clearable: true},
          },
          {
            type: 'input',
            id: 'lastname',
            label: '姓氏',
            el: {placeholder: '请输入姓氏', clearable: true},
          },
          {
            type: 'input',
            id: 'company',
            label: '公司名称',
            el: {placeholder: '请输入公司名称', clearable: true},
          },
          {
            type: 'input',
            id: 'country',
            label: '国家/地区',
            el: {placeholder: '请输入国家/地区', clearable: true},
          },
          {
            type: 'input',
            id: 'state',
            label: '州',
            el: {placeholder: '请输入州', clearable: true},
          },
          {
            type: 'input',
            id: 'city',
            label: '城市',
            el: {placeholder: '请输入城市', clearable: true},
          },
          {
            type: 'input',
            id: 'address',
            label: '详细地址',
            el: {placeholder: '请输入详细地址', clearable: true},
          },
          {
            type: 'input',
            id: 'postalcode',
            label: '邮政编码',
            el: {placeholder: '请输入邮政编码', clearable: true},
          },
          {
            type: 'input',
            id: 'phone',
            label: '电话',
            el: {placeholder: '请输入电话', clearable: true},
          },
          {
            type: 'input',
            id: 'email',
            label: '电子邮件',
            el: {placeholder: '请输入电子邮件', clearable: true},
          },
          // {
          //   type: 'input',
          //   id: 'currency',
          //   label: '货币代码',
          //   el: {placeholder: '请输入货币代码', clearable: true},
          // },
          // {
          //   type: 'input',
          //   id: 'paytype',
          //   label: '支付方式',
          //   el: {placeholder: '请输入支付方式', clearable: true},
          // },
          {
            type: 'input',
            id: 'paystatus',
            label: '支付状态',
            el: {placeholder: '请输入支付状态', clearable: true},
          },
          {
            type: 'input',
            id: 'payremark',
            label: '支付备注',
            el: {placeholder: '请输入支付备注', clearable: true},
          },
          {
            type: 'input',
            id: 'ip',
            label: '用户ip',
            el: {placeholder: '请输入用户ip', clearable: true},
          },
          {
            type: 'input',
            id: 'tradeid',
            label: '赢支付交易id',
            el: {placeholder: '请输入赢支付交易id', clearable: true},
          },
          {
            type: 'input',
            id: 'requestid',
            label: '赢支付请求id',
            el: {placeholder: '请输入赢支付请求id', clearable: true},
          },
      ]
      return {
        columns,
        userinfo:{},
        tableConfig: {
          searchForm,
          url: 'payorder/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('payorder:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('payorder:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('payorder:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('payorder:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            orderid :[
              {required: true, message: '请输入订单号', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入用户id', trigger: 'blur'},],
            cardnumber :[
              {required: true, message: '请输入信用卡卡号', trigger: 'blur'},],
            cvv :[
              {required: true, message: '请输入信用卡安全码', trigger: 'blur'},],
            month :[
              {required: true, message: '请输入信用卡有效期(月份:MM)', trigger: 'blur'},],
            year :[
              {required: true, message: '请输入信用卡有效期(年份:YYYY)', trigger: 'blur'},],
            firstname :[
              {required: true, message: '请输入名字', trigger: 'blur'},],
            lastname :[
              {required: true, message: '请输入姓氏', trigger: 'blur'},],
            company :[
              {required: true, message: '请输入公司名称', trigger: 'blur'},],
            country :[
              {required: true, message: '请输入国家/地区', trigger: 'blur'},],
            state :[
              {required: true, message: '请输入州', trigger: 'blur'},],
            city :[
              {required: true, message: '请输入城市', trigger: 'blur'},],
            address :[
              {required: true, message: '请输入详细地址', trigger: 'blur'},],
            postalcode :[
              {required: true, message: '请输入邮政编码', trigger: 'blur'},],
            phone :[
              {required: true, message: '请输入电话', trigger: 'blur'},],
            email :[
              {required: true, message: '请输入电子邮件', trigger: 'blur'},],
            currency :[
              {required: true, message: '请输入货币代码', trigger: 'blur'},],
            amount :[
              {required: true, message: '请输入支付金额', trigger: 'blur'},],
            paytype :[
              {required: true, message: '请输入支付方式', trigger: 'blur'},],
            paystatus :[
              {required: true, message: '请输入支付状态', trigger: 'blur'},],
            payremark :[
              {required: true, message: '请输入支付备注', trigger: 'blur'},],
            ip :[
              {required: true, message: '请输入用户ip', trigger: 'blur'},],
            tradeid :[
              {required: true, message: '请输入赢支付交易id', trigger: 'blur'},],
            requestid :[
              {required: true, message: '请输入赢支付请求id', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          orderid : "", // 订单号
          userid : "", // 用户id
          cardnumber : "", // 信用卡卡号
          cvv : "", // 信用卡安全码
          month : "", // 信用卡有效期(月份:MM)
          year : "", // 信用卡有效期(年份:YYYY)
          firstname : "", // 名字
          lastname : "", // 姓氏
          company : "", // 公司名称
          country : "", // 国家/地区
          state : "", // 州
          city : "", // 城市
          address : "", // 详细地址
          postalcode : "", // 邮政编码
          phone : "", // 电话
          email : "", // 电子邮件
          currency : "", // 货币代码
          amount : "", // 支付金额
          paytype : "", // 支付方式
          paystatus : "", // 支付状态
          payremark : "", // 支付备注
          ip : "", // 用户ip
          tradeid : "", // 赢支付交易id
          requestid : "", // 赢支付请求id
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deletePayorder(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelPayorder(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertPayorder(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updatePayorder(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
            if (!this.hasRole('payorder:update')&&!this.hasRole('payorder:delete')){
              this.tableConfig.hasOperation=false
            }
      }
    }
  }
</script>

<style scoped>

</style>
